// PopupForm.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';


const PopupForm = ({ togglePopup }) => {
    const [payload, setPayload] = useState({
        person_name: "",
        email: "",
        enquiry: "",
        enquiring_for: "",
        school_name: "",
        school_location: "",
        contact_number: "",
        message: "",
        source: ""

    });

    const [formError, setFormError] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);

    const [sourceUrl, setSourceUrl] = useState('');

    useEffect(() => {
      // Set the value of the sourceUrl state to the current page URL
      setSourceUrl(window.location.href);
      setPayload(prevPayload => ({
        ...prevPayload,  // Ensure any other properties in payload are preserved
        source: window.location.href // Set the source to 'fvdfbd'
    }));
    }, []); // Empty dependency array means this runs only once after the initial render

    
    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log(payload);
        // Destructure formData for easier access
        const { person_name, enquiry, enquiring_for, school_name, school_location, contact_number, email, message } = payload;

        // Check if any field is empty
        if (!person_name || !enquiry || !school_name || !school_location || !contact_number || !email || !message || !enquiring_for) {
            //alert("Please fill in all the fields.");
            setFormError(true); // Add error class    
            setFormSuccess(false); // Ensure success class is not applied        
            return;
        }
        setFormError(false); // Remove error class if validation passes

        // Proceed with form submission if validation passes
        const currentDomain = window.location.origin;
        try {
            const response = await axios.post(`https://server.mu20.co/user`, payload);
            console.log(response);
            if (response.status===201){
                setFormSuccess(true); // Add success class
            } 
            else{
                setFormSuccess(false); // Add success class
            }            

        } catch (error) {
            console.log('An error occurred:', error);
            setFormSuccess(false);
        }
    };
   


    
   
const handleChange = (e) => {
        const { value, name } = e.target;
        setPayload({ ...payload, [name]: value });
    };

  

    const handleSelect = (e)=>{
        let {value}= e.target
        let data=payload
        data={...data,'enquiry':value}
        setPayload(data)
    }


 












    return (
       
            <div className="contactForm">
                <button className="close" onClick={togglePopup}>&times;</button>
                <section className="bgBlack">
            <div className="contactPopup bglightBlack">
            <div className={`contactForm ${formError ? 'form-error' : ''} ${formSuccess ? 'form-success' : ''}`}>                
            <div className={`form-message ${formError ? 'form-error' : ''} ${formSuccess ? 'form-success' : ''}`}>
                                <img src="assets/images/tick.png" alt="Success" className="success-image" />
                                <div className='thnx_msg'>Thank You</div>
                                <div className="success-message">
                                    Thank you for reaching out to us! We’ve received your query and our team will get back to you as soon as possible.<br /><br />
                                    Regards,<br />
                                    Team MU20 School of Opportunity
                                </div>
                                <div className='success_logo'>
                                    <img src="/assets/images/whitelogo@4x.png" alt="" />
                                </div>
                            </div>
                            {/* <form onSubmit={handleSubmit}>
                                <label for="fname">Name</label>
                                <input type="text" id="fname" placeholder="Name" onChange={(e)=> handleChange (e) } name='person_name' />

                                <label for="cars">You are inquiring as a</label>
                                
                                <select name="inquiring" id="inquiring" onChange={(e)=>handleSelect (e)}>
                                    <option value="inquiring">You are inquiring as a 2nd</option>
                                    <option value="inquiring">You are inquiring as a</option>
                                </select>


                                <label for="fname">School Name</label>
                                <input type="text" id="fname" placeholder="School Name (if you are a parent, kindly fill in your Ward's school name)" onChange={(e)=> handleChange (e) } name='school_name' />

                                <div className="loaction">
                                    <label for="location">School Location</label>
                                    <input type="tel" id="school-lacation" placeholder="School Location" onChange={(e)=> handleChange (e) }  name='school_location' />

                                    <label for="contact">Contact number</label>
                                    <input type="tel" id="contact-number" placeholder="Contact number" onChange={(e)=> handleChange (e) } name='contact_number' />
                                </div>

                                <label for="email">Email</label>
                                <input type="email" id="email" placeholder="Email" onChange={(e)=> handleChange (e) } name='email' />

                                <label for="message">Message</label>
                                <textarea type="textarea" placeholder="Add a message" onChange={(e)=> handleChange (e) } name='message'></textarea>

                                <button type="submit" onClick={handleSubmit}  className="formSubmit">Submit</button>
                            </form> */}
                            {/* <form onSubmit={handleSubmit} className={formError ? 'form-error' : ''}>
                                <label for="fname">Name</label>
                                <input type="text" id="fname" placeholder="Name" onChange={(e)=> handleChange (e) } name='person_name' />

                                <label for="cars">You are inquiring as</label>
                                
                                <select className='opt_form' name="inquiring" id="inquiring" onChange={(e)=>handleChange (e)}>
                                <option value="You are inquiring as" disabled selected>You are inquiring as</option>                                
                                    <option value="Student">Student</option>
                                    <option value="Parent">Parent</option>
                                    <option value="Teacher">Teacher</option>
                                    <option value="Business">Business</option>
                                </select>


                                <label for="fname">School name (If you are a parent, kindly fill in your ward's school name)</label>
                                <input type="text" id="fname" placeholder="School name (If you are a parent, kindly fill in your ward's school name)" onChange={(e)=> handleChange (e) } name='school_name' />

                                <div className="loaction">
                                    <label for="location">School location</label>
                                    <input type="text" id="school-lacation" placeholder="School Location" onChange={(e)=> handleChange (e) }  name='school_location' />

                                    <label for="contact">Contact number</label>
                                    <input type="tel" id="contact-number" placeholder="Contact number" onChange={(e)=> handleChange (e) } name='contact_number' />
                                </div>

                                <label for="email">Email</label>
                                <input type="email" id="email" placeholder="Email" onChange={(e)=> handleChange (e) } name='email' />

                                <label for="message">Add a message</label>
                                <textarea type="textarea" placeholder="Add a message" onChange={(e)=> handleChange (e) } name='message'></textarea>

                                <button type="submit" onClick={handleSubmit}  className="formSubmit">Submit</button>
                            </form> */}
                            <form className={formError ? 'form-error' : ''} onSubmit={handleSubmit}>
                                <label for="fname">Name</label>
                                <input type="text" id="fname" placeholder="Name" onChange={(e)=> handleChange (e) } name='person_name' />

                                <label for="cars">You are inquiring as</label>
                                
                                <select className='opt_form' name="enquiry" id="enquiry" onChange={(e)=>handleChange (e)}>
                                <option value="You are inquiring as" disabled selected>You are inquiring as</option>                                
                                    <option value="Student">Student</option>
                                    <option value="Parent">Parent</option>
                                    <option value="Teacher">Teacher</option>
                                    {/* <option value="Business">Business</option> */}
                                </select>


                                <label for="fname">School name (If you are a parent, kindly fill in your ward's school name)</label>
                                <input type="text" id="fname" placeholder="School name (If you are a parent, kindly fill in your ward's school name)" onChange={(e)=> handleChange (e) } name='school_name' />

                                <div className="loaction">
                                    <label for="location">School location</label>
                                    <input type="text" id="school-lacation" placeholder="School Location" onChange={(e)=> handleChange (e) }  name='school_location' />

                                    <label for="contact">Contact number</label>
                                    <input type="tel" id="contact-number" placeholder="Contact number" onChange={(e)=> handleChange (e) } name='contact_number' />
                                </div>

                                <select className='opt_form' name="enquiring_for" id="enquiring_for" onChange={(e)=>handleChange (e)}>
                                <option value="Enquiring For" disabled selected>Enquiring For</option>                                
                                    <option value="OxfordMUN_India_24">OxfordMUN India 24</option>                                 
                                    <option value="MU20_Opportunity_Summit_24">MU20 Opportunity Summit 24</option>                                  
                                    <option value="Leadership_and_Adventure_Bootcamp">Leadership and Adventure Bootcamp</option>                                 
                                    <option value="Young_Leaders_Bootcamp">Young Leaders Bootcamp</option>
                                    <option value="Craft_the_Future_Challenge_24">Craft the Future Challenge 24</option>
                                    <option value="Oxford_MUN_Pre-Challenge">Oxford MUN Pre-Challenge</option>
                                    <option value="Educators_Leadership_Retreat">Educators Leadership Retreat (EdLR)</option>
                                    <option value="MU20_Fellowship">MU20 Fellowship</option>
                                    <option value="Residential_Programs">Residential Programs</option>


                                    {/* <option value="Business">Business</option> */}
                                </select>

                                <label for="email">Email</label>
                                <input type="email" id="email" placeholder="Email" onChange={(e)=> handleChange (e) } name='email' />

                                <label for="Source">Source</label>
                                <input value={sourceUrl} className='source_field' readOnly type="text" id="source" placeholder="Source" onChange={(e)=> handleChange (e) } name='source' />

                                <label for="message">Add a message</label>
                                <textarea type="textarea" placeholder="Add a message" onChange={(e)=> handleChange (e) } name='message'></textarea>

                                <button type="submit" onClick={handleSubmit}  className="formSubmit">Submit</button>
                            </form>
                          
                        </div>  

            </div>   
                        
     
                 
        </section>
                                
            </div>
        


        
    );
};

export default PopupForm;
