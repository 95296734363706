import React, {  useEffect, useRef, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './Home.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { Helmet } from 'react-helmet';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// import PureModal from 'react-pure-modal';
// import 'react-pure-modal/dist/react-pure-modal.min.css';

// ----------------Swiper slider import------------------
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css/effect-fade";
// import Swiper from 'swiper/bundle';
// import 'swiper/css/bundle';

// ----------------Gallery slider import------------------
import ImageGallery from 'react-image-gallery';
import { ModalProvider } from 'styled-react-modal';
import { Button, Modal } from 'antd';
import RegisterModal from '../Popup/RegisterModal';

import Popup from 'reactjs-popup';
import PopupForm from './PopupForm'; // Adjust the path as per your project structure

//--------------------Faq Accodian------------------ 
// ----------------Gallery slider start------------------
const imagesnmins = [
    {
        original: '/assets/images/pillarimage/nmins1.jpg',
        thumbnail: '/assets/images/pillarimage/nmins1.jpg',
    },
    {
        original: '/assets/images/pillarimage/nmins2.jpg',
        thumbnail: '/assets/images/pillarimage/nmins2.jpg',
    },
    {
        original: '/assets/images/pillarimage/nmis3.jpg',
        thumbnail: '/assets/images/pillarimage/nmis3.jpg',
    },
    {
        original: '/assets/images/pillarimage/nmins4.jpg',
        thumbnail: '/assets/images/pillarimage/nmins4.jpg',
    },
];
const imagesehis = [
    {
        original: '/assets/images/pillarimage/ehis1(2).jpeg',
        thumbnail: '/assets/images/pillarimage/ehis1(2).jpeg',
    },
    {
        original: '/assets/images/pillarimage/ehis2(2).jpeg',
        thumbnail: '/assets/images/pillarimage/ehis2(2).jpeg',
    },
    {
        original: '/assets/images/pillarimage/ehis3(3).jpeg',
        thumbnail: '/assets/images/pillarimage/ehis3(3).jpeg',
    },
    {
        original: '/assets/images/pillarimage/ehis.jpeg',
        thumbnail: '/assets/images/pillarimage/ehis.jpeg',
    },
];
// ----------------Gallery slider end------------------ 

function MU20OpportunitySummit24() {



    const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
      setShowPopup(!showPopup);
  };
  

    // const handleMouseEnter = (event) => {
    //     event.target.play();
    //   };
    
    //   const handleMouseLeave = (event) => {
    //     event.target.pause();
    //   };

    const [isMuted, setIsMuted] = useState(true);
    const [isActive, setActive] = useState("false");

    const ToggleClass = () => {
        setActive(!isActive);
    };
    // const [isModalOpen, setIsModalOpen] = useState(false);

    // const showModal = () => {
    //     setIsModalOpen(true);
    // }; 
    // const handleOk = () => {
    //     setIsModalOpen(false);
    // }; 
    // const handleCancel = () => {
    //     setIsModalOpen(false);
    // };  
     //Video on Hover Start
  

  const [showIframe, setShowIframe] = useState(false);
  const [playingVideos, setPlayingVideos] = useState(new Set());
  
  // Replace with actual logic to determine if the device is mobile or touch
  // Use window.matchMedia to determine if the viewport is mobile-sized
  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  useEffect(() => {
    if (isMobile) {
      const videos = document.querySelectorAll('.video');
      const observerOptions = {
        root: null, // Use the viewport as the container
        rootMargin: '0px',
        threshold: 0.5, // Adjust this as needed
      };

      const handleIntersection = (entries) => {
        entries.forEach(entry => {
          const video = entry.target;
          if (entry.isIntersecting) {
            if (video.paused) {
              // Attempt to play the video and handle cases where play() might not return a Promise
              const playPromise = video.play();
              if (playPromise !== undefined) {
                playPromise.catch(error => console.error('Play interrupted:', error));
              }
              setPlayingVideos(prev => new Set(prev.add(video)));
            }
          } else {
            if (!video.paused) {
              // Attempt to pause the video and handle cases where pause() might not return a Promise
              const pausePromise = video.pause();
              if (pausePromise !== undefined) {
                pausePromise.catch(error => console.error('Pause interrupted:', error));
              }
              setPlayingVideos(prev => {
                const updated = new Set(prev);
                updated.delete(video);
                return updated;
              });
            }
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersection, observerOptions);
      videos.forEach(video => {
        observer.observe(video);
      });

      return () => {
        videos.forEach(video => {
          observer.unobserve(video);
        });
      };
    }
  }, [isMobile, playingVideos]);

  const handleMouseEnter = (e) => {
    if (!isMobile) {
        const video = e.target;
        video.muted = true; // Keep the video muted to avoid autoplay restrictions
        if (video.paused) {
            // Attempt to play the video and handle cases where play() might not return a Promise
            const playPromise = video.play();
            if (playPromise !== undefined) {
                playPromise.catch(error => console.error('Play interrupted:', error));
            }
        }
    }
};

const handleMouseLeave = (e) => {
    if (!isMobile) {
        const video = e.target;
        if (!video.paused) {
            // Attempt to pause the video and handle cases where pause() might not return a Promise
            const pausePromise = video.pause();
            if (pausePromise !== undefined) {
                pausePromise.catch(error => console.error('Pause interrupted:', error));
            }
        }
    }
};




  //Video on Hover End
    return (
        <>
           <Helmet>
        <title>MU20 Opportunity Summit        </title>
        <meta name="title" content="" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
            <Header />
            {/* <!-- banner section --> */} 

            <div className='gray_theme'>
            <section className="oxfordBanner">
                {/* <div style={{ paddingBottom: "56.25%", maxWidth: "100%", position: "relative" }}><iframe src="https://player.vimeo.com/video/347119375?autoplay=1&loop=1&muted=1&title=0&portrait=0&byline=0&keyboard=0" width="800px" height="450px" style={{ position: " absolute", top: "0px", left: "0px", width: "100%", height: "100%" }} frameBorder="0"></iframe></div> */}
                <div>
                    <video className="sliderBgImage" autoPlay loop playsInline width="100%" muted={isMuted ? true : false}>
                        {/* <source src="assets/videos/OxfordVideo-Com.mp4" type="video/mp4" /> */}
                        <source src="https://musite.b-cdn.net/mu20summitmainpage.mp4" type="video/mp4" />
                    </video>
                </div>
                <div className="banner-content">
                    <h1>MU20 Opportunity Summit '24 </h1>
                    <div className={isActive ? "mute" : "unmute"}>
                        <a onClick={() => { setIsMuted(!isMuted); ToggleClass(); }} className="muteBtn widthAuto" id="btnUnMute"><img className="unmuteIcon" src="/assets/images/mute.svg" alt="unMuteIcon" />
                            <img className="muteIcon" src="/assets/images/unmute.svg" alt="MuteIcon" /></a>
                    </div>
                </div>
            </section >
            {/* <!-- introduction --> */}
            < section className="intro-section" >
                <div className="container">
                    <div className="introduction">
                        <h2 className="secTitle">Introduction</h2>
                        <p>Welcome to the MU20 Opportunity Summit 2024, Asia's biggest high school conference! Join over 2,500 students globally for an extraordinary journey filled with diverse challenges, including debating, entrepreneurship and more. Discover and enhance the best version of yourself, build a global network, and explore future career opportunities. Don't miss out on this transformative experience!</p>
                    </div>
                </div>
            </section >
            {/* <!-- Why OxfordMUN India ? --> */}
            < section className="whyOxfordSec " id='whyOxfordsec' >
                <div className="container">
                    <div className="oxford-mun">
                        <h2 className="secTitle">Why MU20 Opportunity Summit?
                        </h2>
                    </div>
                    <div className="inner-box">
                        <div className="row">
                            <div className="column">
                                <div className="oxford-box">
                                    <h3>Global Networking<br/> Opportunities</h3>
                                    <p>Connect with like-minded peers and industry experts from around the world. The MU20 Opportunity Summit 2024 offers unparalleled networking opportunities, allowing you to build lasting relationships and expand your global network.</p>
                                </div>
                                <div className="oxford-box">
                                    <h3>Immersive Learning <br/> Experience</h3>
                                    <p>Beyond competitions, the summit features interactive sessions, and special events like the Cultural Walk, Hackathon and MU20x Talks, enriching the participants' journey with diverse learning opportunities.</p>
                                </div>
                            </div>
                            <div className="column">
                                <div className="oxford-box">
                                    <h3>Empowerment Through<br/>Engagement</h3>
                                    <p>With activity sizes limited to ensure meaningful interaction, the MU20 Opportunity Summit 2024 fosters a conducive environment for every participant to contribute effectively and grow.
                                    </p>
                                </div>
                                <div className="oxford-box">
                                    <h3>Recognition for <br/> Excellence</h3>
                                    <p>Outstanding participants will be awarded scholarships and special recognition, providing them with unique opportunities for further development and international exposure.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            {/* <!-- Conference Details --> */}
            < section className="conference" >
                <div className="container">
                    <div className="confere-detail">
                        <h2 className="secTitle">Conference Details</h2>

                        <div className="row">
                            <div className="column">
                                <div className="confere-box">
                                    <h3>Who</h3>
                                    <p>Students studying in classes </p><span className="bgColorText"> 8th to 12th can
                                        register</span>

                                </div>
                                <div className="confere-box m-0">
                                    <h3>When and Where</h3>
                                    <span className="bgColorText">19th-20th-21st October, 2024</span>
                                    <p>at The Emerald Heights International School, Indore and NMIMS, Indore</p>
                                  
                                </div>
                            </div>
                            <div className="column confere-reg">
                                <div className="confere-regInner">
                                    <h3>Registration</h3>
                                    <p>To express your interest in joining, schools are
                                        requested to register by <span>30th August 2024</span> Please note that the priority
                                        registrations for students will end on <span>15th September 2024</span> , and final registration
                                        deadlines will be <span>30th September 2024.</span>
                                    </p>

                                        <a className="registerBtn" target=' ' href="https://bit.ly/InterestformMOS"> Register Now </a>

                                    {/* <button className="registerBtn" href="https://bit.ly/InterestformMOS"  onClick={togglePopup} >
                            Register Now 
                            </button> */}

                            {showPopup && (
                                <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                                    <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                                </Popup>
                            )}



                                    {/* <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn" target="_blank"> Register Now </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <section className="whyOxfordSec eventSec">
                <div className="container">
                    <div className="oxford-mun">
                        <h2 className="secTitle">Events</h2>
                    </div>
                    <Swiper
                        breakpoints={{
                            1440: {
                                spaceBetween: 50,
                                slidesPerView: 3
                            },
                            768: {
                                spaceBetween: 40,
                                slidesPerView: 2,
                                centeredSlides: false,
                            },
                            200: {
                                spaceBetween: 20,
                                slidesPerView: 1,
                            }
                        }}
                        // install Swiper modules
                        modules={[Navigation, Autoplay, A11y]}
                        spaceBetween={60}
                        slidesPerView={3}
                        navigation
                        loop={true}
                        // autoplay={{
                        //     delay: 1500,
                        //     disableOnInteraction: false,
                        //     pauseOnMouseEnter: true,
                        //   }}
                        centeredSlides={true}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/pillarimage/debationg_challenge.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                    <h5 className="eventTitle">Debating Challenge</h5>
                                    <p>Engage in intense, thought-provoking debates that sharpen your critical thinking and public speaking skills. The Debating Challenge is designed to push your intellectual boundaries and foster a spirit of healthy competition.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        {/* <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/ScholarsEventR-min.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    <h6 className="eventDate">28<span>March</span></h6>
                                    <h5 className="eventTitle">OxfordMUN Innovation Challenges</h5>
                                    <p>Kickstart your MUN journey with unique challenges, enhancing team dynamics and critical
                                        thinking.</p>
                                </div>
                            </div>
                        </SwiperSlide> */}
                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/pillarimage/theatrics_challenge.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                    <h5 className="eventTitle">Theatrics Challenge</h5>
                                    <p>Unleash your creativity in the Theatrics Challenge, where you can explore acting techniques, directing principles, and scriptwriting. This challenge provides a platform for you to showcase your talent and passion for the performing arts.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/pillarimage/entreprenuership_challenge.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                    <h5 className="eventTitle">Entrepreneurship Challenge </h5>
                                    <p>Turn your innovative ideas into reality with the Entrepreneurship Challenge. Learn from industry experts, develop business plans, and pitch your startups in a competitive environment that nurtures young entrepreneurs.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/pillarimage/impact_challenge.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                    <h5 className="eventTitle">Impact Challenge</h5>
                                    <p>Make a difference with the Impact Challenge, focusing on real-world issues and practical engagement. Collaborate with peers to formulate impactful solutions, and present your ideas to a panel of experts.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/pillarimage/cultural_walk.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                    <h5 className="eventTitle">Cultural Walk</h5>
                                    <p>Celebrate diversity and showcase your heritage in the Cultural Walk. This challenge invites you to present and explore various cultural traditions, fostering a deeper understanding and appreciation of global cultures.</p>
                                </div>
                            </div>
                        </SwiperSlide>

                        


                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/Global Village Gala-min.png" alt='eventImg' />
                                <div className="eventContentBox">
                                    {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                    <h5 className="eventTitle">MU20x Talks </h5>
                                    <p>Share your ideas worth spreading in the MU20x Challenge. this challenge invites you to deliver powerful talks on topics you are passionate about, inspiring your audience and sparking meaningful conversations.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                       
                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/pillarimage/hackthon.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                    <h5 className="eventTitle">Hackathon Challenge</h5>
                                    <p>Compete in the Hackathon Challenge, where coding and problem-solving skills take center stage. Collaborate with fellow tech enthusiasts to develop innovative solutions and tackle real-world challenges in a high-energy environment.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/pillarimage/model_united_nations2.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                    <h5 className="eventTitle">Model United Nations (MUN)</h5>
                                    <p>Step into the shoes of diplomats at the Model United Nations (MUN) Challenge. Engage in diplomatic discussions, draft resolutions, and navigate international relations as you work to solve global issues in a realistic UN simulation.</p>
                                </div>
                            </div>
                        </SwiperSlide>

                    </Swiper>


                    {/* <div className="row">
                        <div className="eventBox">
                            <img src="/assets/images/event1.png" />
                            <div className="eventContentBox">
                                <h6 className="eventDate">28<span>March</span></h6>
                                <h5 className="eventTitle">Model United Nations</h5>
                                <p>Delve into pressing global issues through meticulously curated committee sessions.</p>
                            </div>
                        </div>
                        <div className="eventBox">
                            <img src="/assets/images/event2.png" />
                            <div className="eventContentBox">
                                <h6 className="eventDate">28<span>March</span></h6>
                                <h5 className="eventTitle">Oxford Scholars Challenge</h5>
                                <p>Kickstart your MUN journey with unique challenges, enhancing team dynamics and critical
                                    thinking.</p>
                            </div>
                        </div>
                        <div className="eventBox">
                            <img src="/assets/images/event3.png" />
                            <div className="eventContentBox">
                                <h6 className="eventDate">28<span>March</span></h6>
                                <h5 className="eventTitle">Training Sessions</h5>
                                <p>Led by experts from Oxford, these sessions are designed to prime delegates for success.</p>
                            </div>
                        </div>
                    </div> */}

                </div>
            </section>
            {/* <section className="partnerSec">
                <div className="container-full">
                    <h2 className="secTitle">Partners</h2>

                    <Swiper breakpoints={{
                        1440: {
                            spaceBetween: 25,
                            slidesPerView: 9,
                        },
                        768: {
                            spaceBetween: 20,
                            slidesPerView: 7,

                        },
                        200: {
                            spaceBetween: 20,
                            slidesPerView: 3,
                        }
                    }} 
                        modules={[Pagination, A11y, Autoplay]}
                        spaceBetween={25}
                        slidesPerView={9}
                        pagination={{ clickable: true }}
                        loop={true}
                        autoplay={false}
                        centeredSlides={true}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner2.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner4.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner3.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner2.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner1.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner5.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner3.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner4.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner1.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner2.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner3.png" />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section> */}
            <section className="venueSpotSec">
      <div className="container">
        <h2 className="secTitle">Venue Spotlight</h2>
        <Tabs>
          <TabList>
            <Tab className="ehis"> Emerald Heights International School</Tab>
            <Tab className="nmims">Narsee Monjee Institute of Management Studies</Tab>
          </TabList>

          <TabPanel>
            <div className="venueSpot-inner">
              <ImageGallery
                items={imagesehis}
                autoPlay={true}
                showFullscreenButton={false}
                showPlayButton={false}
                infinite={true}
              />
              <div className="venueSpotContent">
                <h2><span className="textUpper">EHIS:</span> Opening Ceremony</h2>
                <p>We are thrilled to welcome you to The Emerald Heights International School, a beacon of academic brilliance and inspiration. This year, The Emerald Heights International is honored to host the MU20 Summit, cementing its status as one of India’s top educational institutions. Set amidst a lush 100-acre campus in Indore, Madhya Pradesh, this renowned K-12 co-educational day-cum-boarding school is celebrated for its exceptional infrastructure and academic excellence. Recognized as the best in the country, and holding the top spot in Indore and Madhya Pradesh for co-ed day-cum-boarding schools, The Emerald Heights International also boasts the top rank in Infrastructure in India by Education World. Join us at this prestigious venue as we kick off an inspiring journey of learning and leadership at the MU20 Summit.</p>
              </div>
            </div>
          </TabPanel>

          <TabPanel>
            <div className="venueSpot-inner">
              <ImageGallery
                items={imagesnmins}
                autoPlay={true}
                showFullscreenButton={false}
                showPlayButton={false}
                infinite={true}
              />
              <div className="venueSpotContent">
                <h2><span className="textUpper">NMIMS:</span> Conference</h2>
                <p>We are delighted to invite you to the prestigious SKVM’s Narsee Monjee Institute of Management Studies, Indore – a symbol of academic excellence and outstanding infrastructure. As one of Central India’s leading educational institutions, Narsee Monjee Institute is honored to host the two-day MU20 Summit. Located in the bustling city of Indore, Madhya Pradesh, this institute is celebrated for its dedication to academic achievement and its state-of-the-art facilities. With a reputation for having the best infrastructure in Central India, Narsee Monjee Institute sets the benchmark for excellence in education.</p>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </section>


            <section className=" testimonialSec testimonialHomeSec" id='testimonialhomeSec'>
                <div className="container-full">
                    
                    <h2 className="secTitle Testimonial_">Testimonials</h2>
                        
                    <Swiper
  breakpoints={{
    // For large screens (Desktops)
    1440: {
      slidesPerView: 4,
      spaceBetween: 30,
      centeredSlides: true,  // Don't center on large screens
    },
    // For medium screens (Tablets)
    1024: {
      slidesPerView: 2,
      spaceBetween: 20,
      centeredSlides: false,  // Don't center on tablets
    },
    // For small screens (Mobiles)
    768: {
      slidesPerView: 2,
      spaceBetween: 10,
      centeredSlides: true,   // Center slide on mobile
    },
    // For very small screens (Small phones)
    480: {
      slidesPerView: 1,
      spaceBetween: 40,
      centeredSlides: true,   // Center slide on small phones
    },
  }}
  slidesOffsetBefore={0}  // Remove unnecessary offsets for centering
  slidesOffsetAfter={0}
  modules={[Autoplay, A11y]}
  autoplay={{
    delay: 1500,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  }}
  loop={true}
  centeredSlides={true}  // Ensure centering on mobile
>
  <SwiperSlide>
    <div className="testimonialImg">
      <video
        className="testimonialVideo video"
        controls
        muted
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        playsInline
      >
        <source src="https://musite.b-cdn.net/videos/summit_web_test.mp4" type="video/mp4" />
      </video>
      <div className="textWrapper">
        <img src="/assets/images/videoSec.svg" alt="home-img" />
        <h6 className="titleWatch">Watch Story</h6>
      </div>
    </div>
  </SwiperSlide>


  <SwiperSlide>
    <div className="testimonialImg">
      <video
        className="testimonialVideo video"
        controls
        muted
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        playsInline
      >
        <source src="https://musite.b-cdn.net/Summittest2.mp4" type="video/mp4" />
      </video>
      <div className="textWrapper">
        <img src="/assets/images/videoSec.svg" alt="home-img" />
        <h6 className="titleWatch">Watch Story</h6>
      </div>
    </div>
  </SwiperSlide>
  
</Swiper>
                </div>
            </section>


            
            <section className="reviewSec">
                <div className="container-full">

                    <Swiper

                        // install Swiper modules
                        modules={[Autoplay, Navigation, A11y, EffectFade]}
                        spaceBetween={0}
                        slidesPerView={1}
                        loop={true}
                        autoplay={{
                            delay: 1500,
                            disableOnInteraction: true,
                            pauseOnMouseEnter: false
                          }}
                        centeredSlides={true}
                        effect="fade"
                        navigation={true}
                    //onSwiper={(swiper) => console.log(swiper)}
                    //onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide>
                            <div>
                                <img src="/assets/images/pillarimage/60000000000.jpg" alt='home-img' />
                                <div className="reviewContent">
                                    <h3 className="reviewTitle">600,000+ STUDENTS REACHED</h3>
                                    <p className='fs-14 pt-10'>An extensive series of pre-conference orientations were conducted over three months across schools nationwide, engaging a vast audience.</p>
                                    {/* <p>Students Engaged</p> */}
                                </div>
                            </div>
                        </SwiperSlide> 
                        <SwiperSlide>
                            <div>
                                <img src="/assets/images/300+img.jpg" alt='home-img' />
                                <div className="reviewContent">
                                    <h3 className="reviewTitle">300+ SCHOOLS ENGAGED</h3>
                                    <p className='fs-14 pt-10'>Through AFS and MU20 School of Opportunity’s expansive school network, the summit connected with a diverse array of educational institutions during pre-conference interactions.
                                    </p>
                                    {/* <p> Nationalities Represented</p> */}
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <img src="/assets/images/pillarimage/6countries.jpg" alt='home-img' />
                                <div className="reviewContent">
                                    <h3 className="reviewTitle">6 COUNTRIES REPRESENTED</h3>
                                    <p className='fs-14 pt-10'>The summit was a melting pot of global perspectives, with participants from over six countries discussing some of the world’s most pressing issues.
                                    </p>
                                    {/* <p> Conferences Held</p> */}
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <img src="/assets/images/pillarimage/2500+.jpg" alt='home-img' />
                                <div className="reviewContent">
                                    <h3 className="reviewTitle">2500+ YOUNG VISIONARIES ON CAMPUS </h3>
                                    <p className='fs-14 pt-10'>A signiﬁcant gathering of youth came together to be part of this transformative movement.</p>
                                    {/* <p> Academic Mentors</p> */}
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <img src="/assets/images/pillarimage/24_commit.jpg" alt='home-img' />
                                <div className="reviewContent">
                                    <h3 className="reviewTitle">24+ COMMITTEES AND AGENDAS</h3>
                                    <p className='fs-14 pt-10'>The summit ensured inclusivity and breadth in discussions, covering a wide range of  topics crucial for the current generation.
                                    </p>
                                    {/* <p>Operates in 7 Countries</p> */}
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>




                    {/* <img src="/assets/images/reviewImg.png" alt='home-img' />
                    <div className="reviewContent">
                        <h3 className="reviewTitle">Last year Summit</h3>
                        <p>OxfordMUN India is more than a conference; it's a movement towards nurturing informed</p>
                    </div> */}
                </div>
            </section>

   





            <section className="joinSec">
                <div className="container">
                    <div className="row impact-report-box">
                        
                        <div className="column content-wrapperCol reportimpact ">
                            <div className="content-wrapper">
                                <h5 className="contentTitle">IMPACT REPORT 2023 </h5>
                                <p>
                                The MU20 School of Opportunity proudly hosted the MU20 High School Summit 2023, an event that brought together over 2500 students from across the nation. This prestigious summit was a remarkable convergence of young minds, where participants engaged in a variety of challenges designed to foster leadership, innovation, and global thinking. The summit provided a dynamic platform for students to explore their potential, exchange ideas, and build lasting networks. The success of the MU20 High School Summit 2023 underscores our commitment to creating impactful opportunities for the next generation of leaders, ensuring that they are well-prepared to navigate and shape the future.
                                    <br/>
                                    <br/>
To learn more about the conference and its impact, find the 2023 Impact Report below.
                                </p>
                                <a href="https://bit.ly/MU20SummitImpactReport" className=" white_btn" target="_blank"> EXPLORE IMPACT REPORT</a>
                            </div>
                        </div>
                        <div className="column  reportimpact-img">
                            <img src="/assets/images/front_cover.png" alt='home-img' />
                        </div>
                    </div>
                </div>            
            </section>


            <section className="joinSec movement_box">
            <div className="container">

            <div className="row">
                        <div className="column logoImage">
                            <img src="/assets/images/mu20_brochure 20.png" alt='home-img' />
                        </div>
                        <div className="column content-wrapperCol">
                            <div className="content-wrapper">
                                <h5 className="contentTitle">Join the movement </h5>
                                <p>
                                The MU20 Opportunity Summit 2024 is a dynamic platform designed to nurture informed, empathetic, and capable future leaders. We invite you to be part of this groundbreaking event, whether by participating, supporting its progress, or contributing to its mission. Dive into a journey where you can help shape a brighter future through the power of leadership, innovation, and global collaboration. Join us and be a catalyst for change.
                                
                                </p>
                                <a href="https://musite.b-cdn.net/mu20_opportunity_summit_brochure2024.pdf" class=" white_btn" target="_blank"> Download Brochure </a>
                            </div>
                        </div>
                    </div>

                </div>
                </section>

                    
                
           
            <section className="callToAction">
                <div className="container  ">
                    <div className="  callToAction-inner">
                        <div className="row">
                            <div className="column callToActionLeft">
                                <h5>Ready to make a <br /> difference? </h5>
                                <p>Register Your School for MU20 Opportunity Summit 2024 Today!</p>
                            </div>
                            <div className="column callToActionRight">
                                <p>Register Your School Today for the MU20 Opportunity Summit 2024.
                                    Follow the link below to reserve your spot and embark on an extraordinary journey of discovery, leadership, and innovation. Connect with students from across the globe, take on exciting challenges, and build a network that will shape your future.

                                    <br/>
                                    <br/>
                                    Click the link below to secure your place at this premier event and start an incredible journey of exploration, leadership, and innovation. Join students from all over the world, engage in exciting challenges, and build a network that will empower your future
                                </p>
                                {/* <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn" target="_blank"> Register Now </a> */}
                                <a className="registerBtn" target=' ' href="https://bit.ly/InterestformMOS"> Register Now </a>

                            {showPopup && (
                                <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                                    <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                                </Popup>
                            )}
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className="faqSec">

                <div className="container row faqInner">
                    <div className="faqInnerLeft">
                        <div className="faqInnerLeft-inner">
                            <img src="/assets/images/whitelogo@4x.png" alt="home-img" />
                            <p className="fs-18">Make a Difference with Us: Register Your School for MU20 Opportunity Summit 2024 Today!</p>
                            <p className="fs-18">Join the MU20 Opportunity Summit 2024 and become a catalyst for change. This is more than just a summit—it's a platform where your voice, ideas, and actions can create real impact. Whether you're passionate about solving global challenges, leading innovative projects, or connecting with like-minded peers, this is your chance to make a difference. Step into a world of opportunity, where you can shape the future, inspire others, and leave a lasting legacy. Together, let's make a mark that counts.</p>
                            {/* <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn " target="_blank"> Register Now </a> */}
                           
                            <a className="registerBtn" target=' ' href="https://bit.ly/InterestformMOS"> Register Now </a>

                            {showPopup && (
                                <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                                    <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                                </Popup>
                            )}


                            <p className="fs-18 p-0">Still Have a question?  No worries.</p>

                            <div className="contactDetail">
                                <a href="mailto:madhur.oza@mu20.co" ><img src="/assets/images/email.svg" alt="home-img" />madhur.oza@mu20.co </a>
                                <a href="tel:7987519367" ><img src="/assets/images/whatsapp.svg" alt="home-img" />7987519367</a>
                            </div>
                            <div className="socialLinks">

                                <a href=" https://www.instagram.com/mu20.world?igsh=MThkY3NkeGJ3bHNtaA==" target="_blank">
                                    <img src="/assets/images/insta.svg" alt="home-img" /></a>
                                <a href="https://www.linkedin.com/company/mu20/" target="_blank">
                                    <img src="/assets/images/linkdine.svg" alt="home-img" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="faqInnerRight">
                        <h2 className="secTitle">FAQs</h2>
                        <div className="faqBlock">
                            <Accordion allowZeroExpanded>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            Who can attend the MU20 Opportunity Summit?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p className="faqContent">Students in grades 9-12 have the opportunity to engage in a variety of other challenges offered at the summit.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        What challenges  are included?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>The MU20 Opportunity Summit features a variety of challenges, including Model UN, Theatrics, Entrepreneurship, Debate, Impact, Fashion, and Hackathon. Additionally, participants can share ideas at MU20x Talks and engage in enriching cultural exchanges.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        Where is the summit held?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>The opening ceremony is at The Emerald Heights International School; the conference is at NMIMS in Indore.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        When is the summit?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>October 19th to 21st, 2024.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        How can students register?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Schools must register by September 10th, and student registrations must be completed by September 30th.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        What is the cost?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Registration Fee + 2 Breakfast, 2 Lunch and 3 Hi-tea: ₹3,500; Registration Fee + Accommodation at Raddison Blu and Indore Marriot + Intra-City Travel + 3 Breakfast, 3 Lunch, 3 Hi-tea and 3 Dinner</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        Who leads the sessions?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p> Experts from partnering organizations and industries lead the sessions and activities.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                               

                            </Accordion>

                        </div>
                    </div>
                </div>
            </section>
            </div>
            
            {/* <Button type="primary" onClick={showModal}>
                 Open Modal
                </Button> */}
            {/* <RegisterModal /> */}
            {/* <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <p>Some contents...</p>
                <p>Some contents...</p>
                <p>Some contents...</p>
            </Modal> */}
            <Footer /> 
        </>
    )
}

export default MU20OpportunitySummit24;
